.listing_container {
    margin-top: 8px;
}

.room_heading{
    font-weight: bold;
}

.listing_container_ul {
    list-style-type: none;
    margin-top: 8px !important;
    padding: 0 !important;
}

.list_ul {
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border: 1px solid hsl(26deg 4% 33%);
    border-radius: 12px;
}

.listing_li {
    border-bottom: 1px solid hsl(26deg 4% 33%);
    padding: 10px 16px;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    font-size: 18px;
}



.listing_li_selected{
    font-weight: bold;
}
.listing_li_no_selected{
    cursor: pointer;
}


.listing_li:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: none;
    padding: 10px 16px;
}

.listing_li_container:hover > .edit_icon {
    /* display: block; */
}

.edit_icon{
    display: none;
}

.listing_li_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name_li_container{
    display: flex;
    gap: 12px;
}

.listing_name{
    margin-top: 2px;
}
.form-group {
   
}
  
.form_group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}
  
.form_group label {
    position: relative;
    cursor: pointer;
    margin: 0 !important;
}
  
.form_group label:before {
    content: "";
    background-color: transparent;
    border: 1px solid #28a745;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    /* margin-right: 5px; */
    background-color: #ffffff;
}
  
.form_group input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 9px;
    left: 8px;
    width: 6px;
    height: 8px;
    border: solid #28a745;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.radioImage{
    cursor: pointer;
}