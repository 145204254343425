.container {
    padding: 25px;
    color: black;
}
.room_heading{
    font: 28px Basis-Grotesque-Pro-Regular;
}
.feature_heading{
    font-family: "Basis-Grotesque-Pro-Medium";
    font-weight: 400;
    font-size: 18px;
    margin-top:24px ;
}
.all_selection_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
}
.submitted_task_heading{
    font-size:18px
}
.all_selection{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.all_selection_heading{
    font-size:18px
}


.form-group {
}
  
.form_group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}
  
.form_group label {
    position: relative;
    cursor: pointer;
    margin: 0 0 1px 0 !important;

}
  
.form_group label:before {
    content: "";
    background-color: transparent;
    border: 1px solid #28a745;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    background-color: #ffffff;
}
  
.form_group input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 7px;
    left: 6px;
    width: 5px;
    height: 8px;
    border: solid #28a745;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.task_container{
    display: flex;
    gap: 18px;
    margin-top: 12px;
    flex-wrap: wrap;
    height: calc(100vh - 340px);
    overflow-y: auto;
}

.spinner_loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.submit_btn_container{
    display: flex;
    justify-content: flex-end;
}