#side-nav {
  flex-direction: column;
  height: 100vh;
  width: 230px;
  padding: 28px 0px 0px 0px;
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  background-color: #000000;
  transition: 0.5s;
  align-items: flex-start;
  /* box-shadow: 3px 0px 10px 0px rgb(216 216 216); */
  overflow: hidden;
}
.scrollbarheight {
  height: 449px;
}
#side-nav .column {
  flex-direction: column;
  width: 100%;
  /* overflow: hidden; */
  /* overflow-y: auto; */
}
#side-nav .mt-2 {
  margin-top: 2.5rem !important;
}

.navbar-logo {
  padding: 0px 10px;
  margin: 0;
  align-self: center;
  display: inline-block;
  padding-top: 0.3125rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-logo img {
  width: 155px;
}

.nav-link {
  all: unset;

  padding: 0.4rem 1rem;
  text-decoration: none;
  /* font-weight: bold; */
  cursor: pointer;
  height: 39px;
  font-size: 18px;
  border-left: 5px solid transparent;
  display: flex;
  align-items: center;
  width: unset;
}

.dropdown-btn {
  all: unset;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  border-left: 5px solid transparent;
  display: flex;
  align-items: center;
  width: 100%;
}

.nav-link.active {
  border-left: 3px solid #ffffff;
  background: #333333;
}

.dropdown-btn.active {
  border-left: 5px solid #74b44a;
  background: #e8f9d2;
}

.nav-link img {
  width: 16px;
  margin: 0 15px 0 10px;
}

.dropdown-btn img {
  width: 16px;
  margin: 0 15px 0 10px;
}
.dropdown-container {
  display: none;
}

.dropdown-container .sub-menu-lable {
  margin-left: 41px;
}

.margin-t-sidebar {
  margin-top: 50px;
}
.build-name {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 94px;
}
.build-name .text {
  text-align: center;
  color: white;
}
