.container{
    padding: 0px 10px !important;
}

.filter_and_tab_container{
    position: relative;
}

.tab_container  :global .nav-tabs  {
    display: flex !important;
}

.tabs_view{
    border: 1px solid #64bc51;
    border-radius: 8px;
    height: 41px;
    width: 70%;
}

.tabs_view a{
    padding: 0px 18px;
    background-color: transparent;
    color: black;
    font-size: 14px !important;
    border:none !important;
    display: flex;
    justify-content: center;
}

.tabs_view a:hover{
    padding: 0px 18px !important;
    background-color: transparent !important;
    color: black !important;
    border:none !important;
    font-size: 14px !important;
}

.tab_container :global .nav-tabs .nav-link.active{
    border: none !important;
    border-radius: 8px!important;
    padding: 0 18px !important;
}

.filter_container{
    position: absolute;
    right: 0;
    top: 10px;
}

