/* @font-face {
  font-family: "Basis-Grotesque-Pro-Regular";
  src: url("./assets/fonts/BasisGrotesquePro-Regular.woff"),
    url("./assets/fonts/BasisGrotesquePro-Regular.woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: "Basis-Grotesque-Pro-Bold";
  src: url("./assets/fonts/BasisGrotesquePro-Bold.woff"),
    url("./assets/fonts/BasisGrotesquePro-Bold.woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
body {
  margin: 0;
  /* font-family: "Darker Grotesque", sans-serif; */
  /* font-family: , Open Sans; */
  font-family: "Basis-Grotesque-Pro-Regular", Helvetica, sans-serif !important;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  z-index: 999;
}
