.modal-40w {
  width: 400px;
}
.confirmation .yes {
  min-width: 154px;
  height: 46px;
  background: #ffd502;
  margin-right: 14px;
  border-color: #ffd502;
}

.confirmation .no {
  min-width: 154px;
  height: 46px;
  margin-left: 22;
  background: #1a1a1a;
  border-color: #1a1a1a;
  color: white;
}
.confirmation .no:not(:disabled):not(.disabled):active {
  background: #1a1a1a;
  border-color: #1a1a1a;
  color: white;
}
.confirmation .img-confirmation {
  padding-bottom: 6px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 104px;
}
.confirmation .sub-heading {
  font-size: 17px;
}
