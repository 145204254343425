.img-test-recommend {
  position: absolute;
  right: 9px;
  top: 6px;
  width: 27px;
  cursor: pointer;
}

.image-container {
  position: relative;
}

.error-image-container {
  position: relative;
}

.image-container:hover .overlay-container  {
  opacity: 1;
}

.image-container:hover  .img-test-recommend {
  opacity: 0.3;
}

.image-container:hover  .uploaded-image  {
  opacity: 0.3;
}

.uploaded-image{
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.overlay-container {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.overlay{
  cursor: pointer;
  width: 26px
}