.overlay_container{
    cursor: pointer;
    width: 100%;
}

.filter_icon_container{
    width: 24px;
}
.popover_filters {
    padding-inline: 0px;
    border-radius: 0 !important;
    padding-block: 0px !important;
    box-shadow: 0px 3px 6px #00000029;
}
.popover_container {
  min-width: fit-content;
  border: 1px solid grey!important;

}

:global(.arrow::before) {
  border-bottom-color:black !important
}
.popover_filters ul {
    margin-bottom: 0px;
    padding-left: 0px;
    flex-wrap: wrap;
    background: white 0% 0% no-repeat padding-box;
    border-radius: 12px !important;

  }
.popover_filters li {
    cursor: pointer;
    border-bottom: 1px solid grey;
    padding: 1rem 22px;
    font: normal normal normal 15px Basis-Grotesque-Pro-Regular;
    letter-spacing: 0px;
    color: #000000;
    display: flex;
    position: relative;
  }

.popover_filters li:last-child{
    border-bottom: none !important;
}

.lastRow{
  }
  
.evenList{
}

.popover_filters li:hover , .is_selected{ 
  color: #62b550;
}
.is_selected{
  color: #62b550  !important;
}