.modal_container :global .modal-dialog{
  max-width: 650px !important;
}

.container_dialog_body{
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px !important;
  flex-direction: column;
}

.image_container {
  position: relative;
  display: flex;
  justify-content: center;
}

.image_container_btn_submit {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 48px;
}

.image_icons{
  position: absolute;
  top: 20px;
  right: 20px;
  gap: 12px;
  display: flex;
}


.magnifier-image,.modal_image_magnifier{
  object-fit: contain;
  max-height: 78vh !important;
  max-width: 58% !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2.3%;
}


.modal_image{
  cursor: pointer;
}

.fill_screen_image_modal_body{
  padding: 0 !important;
}

.full_screen_icons{
  font-weight: bolder;
  cursor: pointer;
  width: 22px;

}
.close_icon {
  width: 18px;
  height: 20px;
}
.rotate_icon {
  width: 22px;
}

.edit_mode_txt{
  font-weight: bold;
  position: absolute;
  top: 24px;
}

.submit_btn{
  height: 36px !important;
  margin-top: 20px;
}

.loader{
  margin-top: 12px;
}
