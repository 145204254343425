.image_container {
 
}
.default_image{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 140px);
}

.content_container {
   padding: 25px;
   color: black;
}