.inst_img_cont {
  position: relative;
  height: 116px !important;
  width: 116px !important;
}

.taskImg {
  opacity: 1;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  transition: .5s ease;
  backface-visibility: hidden;
}

.checkbox_container {
  position: absolute;
  top: 5px;
  right: 5px;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form_group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form_group label {
  position: relative;
  cursor: pointer;
}

.form_group label:before {
  content: "";
  background-color: transparent;
  border: 1px solid #28a745;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  background-color: #ffffff;
}

.form_group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 6px;
  width: 5px;
  height: 8px;
  border: solid #28a745;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.img_title_chip {
  font-size: 12px;
  text-align: center;
  width: 80px;
  margin: auto;
  margin-top: 8px;
  color: black;
  font-weight: bold;
  text-decoration: underline;
}

.image_container {
  position: relative;
}

 .image_container:hover .overlay_container  {
  opacity: 1;
 
 } 

.image_container:hover  .taskImg  {
  opacity: 0.3;
}


.overlay_container {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.overlay{
  cursor: pointer;
  width: 26px
}