.setting-tab-btns {
  background: #fff;
  padding-left: 30px !important;
}
.setting-tab-btns .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-bottom: none;
  border-color: white !important;
}
.setting-tab-btns .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: white !important;
  border-left: 0px;
  border-bottom: 3px solid #ffffff !important;
  background: #e8f9d2 !important;
  font-family: "Basis-Grotesque-Pro-Bold" !important;
}
.setting-tab-btns a:hover {
  color: #212529;
}

.setting-tab-btns a {
  color: #84857a;
  font-size: 15px;
  padding: 10px 15px;
  font-size: 16px;
}
.setting-tab-btns .nav-link {
  font-weight: normal;
  padding: 10px 40px;
}

.billing-table .table td p {
  margin: 0;
  font-size: 14px;
  line-height: 37px;
  font-weight: 600;
  text-decoration: none;
  color: #2f481e;
}
.billing-table .table thead th {
  font-size: 13px !important;
}

.heading-rem {
  font-size: 15px;
  color: #2f481e;
}

.remaining-bill {
  font-size: 27px;
  margin-right: 10px;

  color: #2f481e;
  font-weight: 600;
}

.bill-amount {
  display: flex;
  min-width: 200px;
}

.license-counter {
  color: #74b44a;
  font-size: 40px;
  display: flex;
}

.mt-less1 {
  margin-top: -7px;
}
.mt-less2 {
  margin-bottom: -7px;
}

.span-line {
  max-width: 20px;
  border: 1px solid #dee2df;
  display: block;
  margin-left: 3px;
}
.licences-details {
  border-right: 1px solid #e7ece8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.licenses-price {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-text {
  font-size: 24px;
  color: #2f481e;
  font-weight: bold;
}

.pd-mar {
  padding-left: 20%;
}

.modal-texting {
  margin-left: 30px;
  padding-right: 30px;
  margin-right: 25px;
}

.counter-btn i {
  cursor: pointer;
}

.border-div {
  border-bottom: 2px solid #dee2df;
}

.company-details textarea:focus {
  border-color: #dddddd;
}
.card-on-file .card-file-data {
  height: 55px;
  border-radius: 8px;
  background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  box-shadow: 0 2px 52px -20px rgba(132, 133, 122, 0.5);
}
.card-on-file .card-number {
  color: #2f481e;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 8px;
  margin-left: 25px;
}
.card-image {
  margin-left: 20px;
}

.american-card-icon {
  height: 37px;
  width: 42px;
}

.bg-table-billing-shadow {
  box-shadow: 0 2px 52px -20px rgba(132, 133, 122, 0.5);
  border-radius: 8px;
}

.spinner-div {
  display: flex;
  height: inherit;
  margin: 0;
  justify-content: center;
  align-items: center;
}

.align-text-coupon {
  display: flex;
  justify-content: center;
  /* border-top: 1px solid #dee2df; */
}

.border-line-modal {
  border-top: 1px solid #dee2df;
  height: 1px;
  width: 274px;
}

/* The container must be positioned relative: */
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.react-tel-input .country-list {
  background-color: #f3f3f3 !important;
  width: 100% !important;
  margin: 35px 0 10px -1px !important;
}
.react-tel-input .form-control {
  width: 100% !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  background: #f3f3f3 0% 0% no-repeat padding-box !important;
  border: 1px solid #575350 !important;
  height: 53px !important;
  outline: 0 !important;
  /* font-family: Basis-Grotesque-Pro-Regular; */
  font-size: 17px !important;
}
.react-tel-input .flag-dropdown.open {
  width: 100% !important;
}
.react-tel-input .flag-dropdown {
  top: auto !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: #e1ecde !important;
}
.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: transparent !important;
  box-shadow: none !important;
}

.flag-dis > .selected-flag {
  cursor: not-allowed;
}

.flag-enable > .selected-flag {
  cursor: pointer;
}

.react-tel-input .selected-flag {
  margin-top: -26px !important;
}
.react-tel-input .form-control:focus {
  box-shadow: none !important;
}

.center-column h3 {
  font-size: 23px !important;
  font-family: "Basis-Grotesque-Pro-Medium";
  font-weight: normal;
}
.center-change-pass {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.center-change-pass img {
  width: 149px;
  margin-top: 26px;
}
.center-change-pass p {
  font-size: 17px;
  margin-top: 11px;
  font-family: "Basis-Grotesque-Pro-Medium";
}
.react-tel-input .flag-dropdown {
  border: none !important;
}
