.test_heading_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.test_heading{
    font: 28px Basis-Grotesque-Pro-Regular;
}
.test_top_btn{
    display: flex;
    align-items: center;
    gap: 12px;
}
.room_hading_container{
    font-family: "Basis-Grotesque-Pro-Medium";
    font-size: 16px;
    margin-top:16px;
}

.delete_icon_cont{
    cursor: pointer;
    display: none;
}

.approve_container{
    display: flex;
    align-items: center;
}
.un_approve_btn{
    font-family: "Basis-Grotesque-Pro-Medium";
    font-weight: 400;
    height: 30px !important;
    font-size: 12px !important;
    background: transparent !important;
    border:1px solid gray  !important
}

.approve_btn{
    font-family: "Basis-Grotesque-Pro-Medium";
    font-weight: 400;
    height: 30px !important;
    font-size: 12px !important;
    background: #71db551c !important;
    border:1px solid #71db55  !important
}

.check_image{
    width: 20px;
}

.feat_container{
    height: calc(100vh - 340px);
    overflow-y: auto;
}

.feature_name{
    margin-bottom: 20px;
}


.task_container{
    display: flex;
    gap: 18px;
    margin-top: 12px;
    flex-wrap: wrap;
}

.divider{
    width: 100%;
    height: 1px;
    background-color: #d9d4d4;
    margin-top: 20px;
    margin-bottom: 10px;
}

.margin_from_bottom{
    margin-bottom: 60px;
}

.loader{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.app_client_loader{
    display: flex;
    justify-content: center;
}
