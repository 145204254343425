.room_detail{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding: 0 4px;

}
.room_heading{
    font-weight: bold;
    font-size: large;
}

.room_icon{
    cursor: pointer;
}

.form-group {
   
}
  
.form_group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}
  
.form_group label {
    position: relative;
    cursor: pointer;
    margin: 0 !important;
}
  
.form_group label:before {
    content: "";
    background-color: transparent;
    border: 1px solid #28a745;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    /* margin-right: 5px; */
    background-color: #ffffff;
}
  
.form_group input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 9px;
    left: 8px;
    width: 6px;
    height: 8px;
    border: solid #28a745;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
  

.display_feature{
    visibility: visible;
    opacity: 1;
    max-height: fit-content;
    transition: opacity 0.5s ease-in, max-height 0.5s ease-in;
}
.hide_feature{
    visibility:hidden;
    opacity: 0;
    max-height: 0;
    transition: max-height 0.25s ease-in;
} 