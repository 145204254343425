.modal_container :global .modal-dialog{
    width: 35%;
    max-width: none !important;
}

.modal_container  :global .modal-dialog .modal-content {
    border-radius: 0.9rem !important;
    padding: 0 8px;
}
.modal_container_header {
    border: none !important;
}

.modal_title_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal_title {
    font-family: "Basis-Grotesque-Pro-Medium";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0px;
    color: #000000;
    text-align: center;
}
  
.modal_container_body{
    padding-top: 12px !important;
}

.modal_container_footer {
    border: none !important;
    display: flex !important;
    justify-content: center !important;
    padding-top: 0px !important;
}

.cross_icon,.back_icon{
    width: 16px;
    cursor: pointer;
}
.spinner {
    margin-top: 12px;
    display: flex;
    justify-content: center;
}

.footer_next_btn {
    min-width: 154px !important;
    height: 46px !important;
    background: #FFD502 !important;
    border-color: #FFD502 !important
}

.footer_cancel_btn {
    min-width: 154px !important;
    height: 46px !important;
    background: #1A1A1A !important;
    border-color: #1A1A1A !important;
    color: white !important;
}

.title_input_field{
    font-size: 15px !important;
    font-weight: 500;
    border-radius: 8px;
    background: #f3f3f3 0 0 no-repeat padding-box;
    border: 1px solid #575350;
    height: 52px;
    width: 100%;
    outline: 0;
    padding: 16px;    
}

.select_test_field{
    margin-bottom: 8px;
}