.login-page {
  background-color: #f4eadf;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.signup-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.WIT-logo {
  /* width: 35%; */
  /* max-width: 464px; */
  margin-bottom: 30px;
}

.placeholder-icon {
  color: #84857a;
  margin-right: -52px;
  position: absolute;
  padding: 14px 17px;
}
.placeholder-icon-password {
  color: #84857a;
  right: 17px;
  position: absolute;
  top: 15px;
  cursor: pointer;
}
.placeholder-icon-password img {
  max-width: 27px;
}

.placeholder-icon img {
  max-width: 20px;
}

.placeholder-icon-submit {
  right: 0px;
  position: absolute;
  top: 8px;
  cursor: pointer;
}
.placeholder-icon-submit img {
  max-width: 22px;
  margin-right: 14px;
}

.forgot-password {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  text-decoration: underline;
}

.login-btn {
  height: 50px;
  background: #fed636 0% 0% no-repeat padding-box;
  border-radius: 12px;
  border: 0;
  letter-spacing: 1.31px;
  font: normal normal bold 20px Basis-Grotesque-Pro-Regular;
  border: 0;
  padding: 7px 10px;
  width: 100%;
  cursor: pointer;
}
.login-btn:focus {
  outline: none;
}
.black-text {
  color: #000000;
}

.error-div {
  background-color: #b44a4a;
  color: white;
  padding: 60px 0 4px 22px;
  border-radius: 7px;
  margin-top: -55px;
  margin-left: -2px;
  margin-right: -2px;
  font-weight: 700;
  font-size: small;
}

.email-pass-error {
  background-color: #ff4444;
  color: white;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: -15px;
  border-radius: 5px;
  width: calc(100vw - 20px);
  max-width: 410px;
  display: flex;
  align-items: center;
}
.reset-password .error-text {
  width: 410px;
}
.email-pass-success {
  display: flex;
  align-items: center;
  border: 2px solid white;
  color: white;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: -15px;
  border-radius: 5px;
  width: calc(100vw - 20px);
  max-width: 390px;
}

.mr-n-50 {
  margin-right: -50px;
}

input:focus {
  outline: none;
}

.auth-input-field::placeholder {
  font-style: italic;
  font-weight: bold;
}

.font-bold {
  font-weight: bold;
}

.reset-password-text {
  margin-bottom: 18px;
  margin-top: 10px;
  text-align: center;
  font-size: 17px;

  /* font: normal normal normal 20px/40px Basis-Grotesque-Pro-Regular; */
  /* letter-spacing: 0px; */
  color: #000000;
}

.new-tab-link {
  color: #04444d !important;
  text-decoration: underline;
}

.back-text {
  font: normal normal normal 16px Basis-Grotesque-Pro-Regular;
}
.new-tab-link:hover {
  color: #04444d !important;
}

.margin-top-field {
  margin-top: 16px !important;
}

.font-16 {
  font-size: 16px !important;
}
.forgot-h1 {
  text-align: center;
  font: normal normal bold 25px Basis-Grotesque-Pro-Regular;
  letter-spacing: 0px;
  color: #000000;
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  /* CSS */
  .reset-password .float-label {
    min-width: 350px !important;
  }
  .reset-password .error-text {
    width: 350px;
  }
  .reset-password .reset-pass {
    width: 120px !important;
  }
  .reset-password .email-pass-error {
    width: 350px !important;
  }
  .reset-password .custom-modal-text {
    margin: 20px 10px !important;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  /* CSS */
  .reset-password .float-label {
    min-width: 315px !important;
  }
  .reset-password .reset-pass {
    width: 120px !important;
  }
  .reset-password .email-pass-error {
    width: 315px !important;
  }
  .reset-password .error-text {
    width: 315px;
  }
  .reset-password .custom-modal-text {
    margin: 20px 10px !important;
  }
}
@media screen and (max-width: 320px) {
  /* CSS */
  .reset-password .float-label {
    min-width: 280px !important;
  }
  .reset-password .email-pass-error {
    width: 280px !important;
  }
  .reset-password .error-text {
    width: 280px;
  }
  .reset-password .reset-pass {
    width: 120px !important;
  }
  .reset-password .custom-modal-text {
    margin: 20px 10px !important;
  }
}

@media screen and (max-width: 300px) {
  /* CSS */
  .reset-password .float-label {
    min-width: 245px !important;
  }
  .reset-password .email-pass-error {
    width: 245px !important;
  }
  .reset-password .error-text {
    width: 245px;
  }
  .reset-password .reset-pass {
    width: 120px !important;
  }
  .reset-password .custom-modal-text {
    margin: 20px 10px !important;
  }
}
