.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  width: 100%;
  margin: 0;
  padding-left: 260px;
  padding-right: 30px;
  background: #fff;
  z-index: 2;
}

.half-header {
  position: absolute !important;
  top: 25px !important;
  background: transparent !important;
  z-index: 2 !important;
  width: auto !important;
  padding-left: 50px !important;
  padding-right: 0px !important;
}
.white-text {
  color: white !important;
}

.header .heading-icon {
  width: 26px;
  margin-right: 15px;
}
.header h3 {
  font-size: 26px;
  font-family: "Basis-Grotesque-Pro-Medium";
  font-weight: normal;
}

.open-menu-icon {
  margin-right: 10px;
  font-size: 30px;
  cursor: pointer;
}

/* .header-title {
  margin: unset;
}
.new-rect {
  padding-left: 46px;
  height: 40px;
  width: 270px;
  border-radius: 8px;
  outline: none;
  background-color: #e7ece8;
  border: none;
  padding-right: 37px;

  transition: width 0.15s ease-in-out, height 0.15s ease-in-out;
} */

/* .header .search-box-icon {
  position: absolute;
  z-index: 3;
  cursor: pointer;
  margin: 7px 16px 8px 12px;
}
.header .cancel-box-icon {
  position: absolute;
  z-index: 3;
  width: 13px;
  cursor: pointer;
  margin: 15px 16px 8px 12px;
} */

.new-rect {
  padding-left: 42px;
  height: 40px;
  width: 270px;
  border-radius: 8px;
  outline: none;
  background-color: #e7ece8;
  border: none;
  padding-right: 37px;
  /* transition-property: width; */
  /* transition-duration: 0.14s; */
  /* transition-delay: 0.13s; */
  transition: width 0.15s ease-in-out, height 0.15s ease-in-out;
}
.header .search-box-icon {
  position: absolute;
  cursor: pointer;
  margin: 8px 16px 8px 12px;
}
.header .cancel-box-icon {
  position: absolute;
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin: 12px 0px 8px 239px;
}

.header .search-header-activate {
  color: #2f481e;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  margin-top: 14px;
  padding: 0px 12px;
  display: initial;
  /* transition: all ease-in-out 2s; */
}
.margin-without {
  margin: 0px !important;
}
.header .active-color {
  color: #2f481e;
}

.div-center {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}
.header .non-active-color {
  margin: 8px 47px 5px 19px;
  position: absolute;
  color: #84857a;
}
.my-input {
  transform: scaleX(0);
  opacity: 0;

  transition: opacity 0.4s, transform 0.4s;
  transform-origin: 100% 100%;
}

.my-input.show {
  transform: scaleX(1);
  opacity: 1;
}
.with-margin {
  /* margin: 0px !important; */
  width: 20px !important;
  height: 20px !important;
  margin-top: 11px !important;
  margin-right: 235px !important;
}
.without-margin {
  /* margin: 0px !important; */
  margin-top: 12px;
  /* margin-right: 235px !important; */
}
@media screen and (max-width: 991px) {
  .header {
    padding-left: 30px;
    padding-right: 30px;
  }
  .open-menu-icon {
    display: inline-block;
  }
}

@media screen and (min-width: 992px) {
  .open-menu-icon {
    display: none;
  }
}

.header .go-back {
  display: flex;
  flex-direction: row;
  color: #4aa2b4;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  width: fit-content;
}

.go-back .go-back-text {
  color: #000000;
  font: normal normal normal 16px/24px Basis-Grotesque-Pro-Regular;
  margin-left: 5px;
}

.radio-btn {
  height: 26px;
  width: 26px;
}

.radio-label {
  font-weight: 600;
  font-size: 14px;
  color: #2f481e;
}
