.bg-primary300 {
  background-color: #57932f !important;
}
.bg-light20 {
  background-color: #ffd502 !important;
}
.bg-error {
  background-color: #b44a4a !important;
}
.bg-primary500 {
  background-color: #74b44a !important;
}
.bg-light50 {
  background-color: #84857a !important;
}
.bg-alert {
  background-color: #eae34c !important;
}
.bg-primary900 {
  background-color: #f1f9d2 !important;
}
.bg-light80 {
  background-color: #d0d4d1 !important;
}
.bg-accent500 {
  background-color: #b44a9a !important;
}
.check-active {
  background-color: #edf6f8 !important;
}
.bg-secondary500 {
  background-color: #4aa2b4 !important;
}
.bg-light90 {
  background-color: #e7ece8 !important;
}
.bg-secondary800 {
  background-color: #cfe7ec !important;
}
.bg-light100 {
  background-color: #fff !important;
}
.bg-secondary900 {
  background-color: #edf6f8 !important;
}
.text-primary300 {
  color: #57932f !important;
}
.text-light20 {
  color: #2f481e !important;
}
.text-error {
  color: #b44a4a !important;
}
.text-primary500 {
  color: #74b44a !important;
}
.text-light50 {
  color: #84857a !important;
}
.text-alert {
  color: #eae34c !important;
}
.text-primary900 {
  color: #f1f9d2 !important;
}
.text-light80 {
  color: #dee2df !important;
}
.text-accent500 {
  color: #b44a9a !important;
}
.text-secondary500 {
  color: #4aa2b4 !important;
}
.text-light90 {
  color: #e7ece8 !important;
}
.text-secondary800 {
  color: #cfe7ec !important;
}
.text-light100 {
  color: #fff !important;
}
.text-secondary900 {
  color: #edf6f8 !important;
}
.br-primary300 {
  border-color: #57932f !important;
}
.br-light20 {
  border-color: #2f481e !important;
}
.br-error {
  border-color: #b44a4a !important;
}
.br-primary500 {
  border-color: #74b44a !important;
}
.br-light50 {
  border-color: #84857a !important;
}
.br-alert {
  border-color: #eae34c !important;
}
.br-primary900 {
  border-color: #f1f9d2 !important;
}
.br-light80 {
  border-color: #dee2df !important;
}
.br-accent500 {
  border-color: #b44a9a !important;
}
.br-secondary500 {
  border-color: #4aa2b4 !important;
}
.br-light90 {
  border-color: #e7ece8 !important;
}
.br-secondary800 {
  border-color: #cfe7ec !important;
}
.br-light100 {
  border-color: #fff !important;
}
.br-secondary900 {
  border-color: #edf6f8 !important;
}
.test-border {
  border: 1px solid #000;
}
.disabled-bg-color {
  background-color: #6d6d6d;
}
.disabled-br-color {
  border-color: #6d6d6d;
}
.disabled-text-color {
  color: #6d6d6d;
}

.h4,
h4 {
  font-size: 18px;
  color: #000000;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: #ffd94f;
  color: #1a1a1a;
  border-color: #ffd94f;
}

.btn.btn-primary {
  background: #fed500;
  padding: 10px 20px;
  border-color: #fed500;
  letter-spacing: 0.31px;
  font-weight: 600;
  font-style: normal;
  font-family: "Basis-Grotesque-Pro-Regular";
  color: #1a1a1a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  min-width: 106px;
  height: 43px;
  white-space: pre;
}

.btn.btn-primary svg {
  margin-right: 10px;
}
.btn-primary img {
  margin: 0px 8px 0px 3px;
}
.form-control {
  color: black !important;
  max-width: 100%;
  font-size: 16px;
  font-weight: 500;
  height: 55px;
  border-radius: 8px;
  padding: 10px 15px;
  border: 1px solid #74b44a;
}

.form-control:focus {
  color: #495057;
  background-color: #f3f3f3;
  border-color: #575350;
  outline: 0;
  box-shadow: none;
}


input::-webkit-autofill,
input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}


.avatar-xm {
  width: 30px;
  height: 30px;

  margin-right: 11px !important;
  margin-left: 6px;

  object-fit: cover;
  border-radius: 30px;
}
.avatar-sm {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 30px;
}

.upload-avatar {
  width: 155px;
}

.upload-avatar .image-area {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 155px;
  height: 155px;
  border-radius: 50%;
  overflow: hidden;
  /* border: 1px solid #fff; */
  border: 1px solid #707070;
  box-shadow: 0 0 10px #dcdcdc;
}

.upload-avatar .upload-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #2f481e;
  background-color: #f3f3f3;
  /* background: #fff; */
  border-radius: 60px;
  transition: all 0.1s ease-in-out;
}

.upload-avatar .image-area:hover .upload-icon {
  background: #f3f3f3;
}

.upload-avatar .avatar-lg {
  /* width: 155px; */
  height: 155px;
  object-fit: contain;
  border-radius: 50%;
}

.upload-avatar .upload-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;
}

.upload-avatar p {
  color: #000000;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 0px !important;
}

.main {
  background: #f4eadf;
  display: block;
  width: calc(100% - 230px);
  min-height: calc(100vh - 90px);
  margin-left: 230px;
  margin-top: 90px;
  /* border-top-left-radius: 8px;
  border-top-right-radius: 8px; */
}

.main-content-area {
  padding: 25px 30px 25px 29px;
}

.main-content-area h4 {
  font-family: "Basis-Grotesque-Pro-Medium" !important;
  font-weight: normal !important;
}

table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.table td,
.table th {
  border: 0;
}

.table thead {
  line-height: 15px;
}

.table thead th {
  position: sticky;
  line-height: 20px;
  top: 0;
  background: #f4eadf;
  border: 0;
  vertical-align: sub;
  font-family: "Basis-Grotesque-Pro-Medium" !important;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  color: #000000;
  word-break: normal;
  font-size: 17px !important;
  padding: 8px 18px;
}

tr td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

tr td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.table td {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 10px #64bc514f; */
  /* border: 2px solid #64bc51; */
  vertical-align: middle;
  padding: 5px 0px 5px 20px;
  font-size: 15px;
}
.table td:last-child {
  padding: 5px 15px 5px 17px;
}

.table.extra-padding td {
  padding: 16px 20px;
}

.table td h3 {
  color: #2f481e;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  line-height: 25px;
}

.table td p,
.table td a {
  font-size: 16px;
  font-weight: 600;
  color: #4aa2b4;
  margin: 0;
  line-height: 20px;
  text-decoration: underline;
}

.pagestyle {
  position: relative;
  bottom: 0px;
  right: 25%;
  left: 50%;
  margin-left: -15%;
}

.pagestyle > button {
  margin: 5px 10px;
}

@media screen and (max-width: 992px) {
  .main {
    margin-left: 0;
    width: 100%;
  }
}

.app-spinner {
  position: fixed;
  z-index: 999;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: #14444c !important;
}

.text-success {
  color: #14444c !important;
}
.d-center {
  display: flex;

  justify-content: center;
}

.notShow {
  display: contents !important;
  -webkit-transition: all 0.3s linear !important;
  -o-transition: all 0.3s linear !important;
  transition: all 0.3s linear !important;
  transform: scale(1) !important;
}
.show-this-modal {
  display: block;
  transform: scale(1) !important;
}
.d-center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-modal .modal-footer {
  justify-content: center !important;
  padding: 0rem 0px 1.15rem;
}

.error-modal .modal-footer {
  padding: 0.55rem 0px 1.05rem 1.15rem !important;
}
.custom-modal .modal-content {
  border-radius: 0.9rem;
}
.custom-modal .modal-body {
  padding: 0rem 1rem 0rem 1rem !important;
}
.fade-custom {
  -webkit-transition: all 0.3s linear !important ;
  -o-transition: all 0.3s linear !important ;
  transition: all 0.3s linear !important ;
  transform: scale(1) !important ;
}

.fade.in {
  transform: scale(1) !important ;
}
.custom-modal-text {
  font: normal normal normal 19px Basis-Grotesque-Pro-Regular;
  margin: 25px 40px;
  text-align: center;
  color: black;
}
.list-spinner {
  justify-content: center;
  display: flex;
  align-items: center;
}

.observation-detail-list-spinner {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 150px;
}

.rightside-spinner {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
}

.list-spinner > * {
  color: #14444c !important;
}

.Toastify__toast--success {
  background: #000;
}

.Toastify__toast--error {
  background: #b44a4a;
}

.minHeight {
  min-height: 60vh;
}

.fixHeight {
  height: calc(100vh - 200px);
  padding-right: 15px;
}

.page-link {
  color: #74b44a !important;
}

.page-item.active .page-link {
  color: white !important;
  border-color: #64bc51 !important;
  background-color: #64bc51 !important;
}

.table .next-btn {
  font-size: 24px;
  border: 0;
  background: 0;
  color: #777;
  padding: 8px 12px;
}

.next-btn:disabled {
  cursor: not-allowed;
}

.table .next-btn:focus {
  outline: none;
  background-color: lightgray;
  border-radius: 5px;
}

.ul-style {
  list-style-type: none;
  padding: 0px 10px;
  height: calc(100vh - 215px);
  margin: 5px 0px 0px 0px !important;
  word-break: break-word;
}

.ul-style-no-paginator {
  list-style-type: none;
  padding: 0px 10px;
  height: calc(100vh - 170px);
  overflow-y: auto;
  margin: 5px 0px 0px 0px !important;
}

.rightsideactions {
  display: flex;
  margin: 10px 10px 10px 0px;
  justify-content: flex-start;
}

.pagination {
  justify-content: center;
  padding: 5px 0px;
  margin: 0px !important;
}

.leftside {
  background: #f4eadf;
  width: 60%;
  padding: 5px 5px 0px 20px;
}
.no-border {
  border: none !important;
}
.leftside-header {
  padding: 0px 10px;
  display: block;
}
.leftside-header h4 {
  font-family: "Basis-Grotesque-Pro-Medium" !important;
  font-weight: normal !important;
}

/* .float-label {
  display: flex;
  flex-direction: column;
  position: relative;
}

.float-label input {
  width: 100%;
  height: 56px;
  padding: 15px 17px 0 20px;
  outline: 0;
  border-radius: 10px;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}

.float-label textarea {
  width: 100%;
  min-height: 120px;
  max-height: 200px;
  padding: 25px 17px 0 20px;
  outline: 0;
  border-radius: 10px;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}

.float-label label {
  font-style: italic;
  font-weight: bold;
  padding: 0 20px;
  color: #84857a;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 26px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  margin-top: -8px;
}

.float-label:focus-within label {
  transform: translate(0, 12px) scale(0.75);
}

.float-label .Active {
  transform: translate(0, 12px) scale(0.75);
} */

.float-label {
  display: flex;
  flex-direction: column;
  position: relative;
}

.float-label input {
  font-weight: 500;
  border-radius: 8px;

  background: #f3f3f3 0% 0% no-repeat padding-box;
  border: 1px solid #575350;
  height: 53px;

  width: 100%;
  outline: 0;
  /* font-family: "Basis-Grotesque-Pro-Regular"; */
  font-size: 18px;
}
.float-label input.padding-placeholder {
  padding: 2px 22px 0 50px;
}
.float-label input.padding-without-placeholder {
  padding: 2px 24px 0 24px;
}

.float-label input::placeholder {
  /* color: #000000; */
  color: #6e5654;
}
.error-border input {
  border: 1px solid #ff4444;
}

.float-label textarea {
  font-weight: 500;
  border-radius: 10px;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border: 1px solid #575350;
  height: 53px;
  width: 100%;
  outline: 0;
  font-size: 18px;
}

.float-label textarea.padding-without-placeholder {
  padding: 7.5px 24px 0 17px;
}

.float-label textarea::placeholder {
  /* color: #000000; */
  color: #6e5654;
}
.error-border-textarea {
  border: 1px solid #ff4444 !important;
}

.float-label label {
  padding: 0 25px;
  color: #000000;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 26px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  margin-top: -8px;
  font-size: 18px;
}

.float-label:focus-within label {
  transform: translate(0, 12px) scale(0.75);
}

.float-label .Active {
  transform: translate(0, 12px) scale(0.75);
}

.input-field .Active {
  transform: translate(0, 12px) scale(0.75);
}

.input-field::placeholder {
  /* font-style: italic; */
  color: #000000;
  /* font-weight: bold; */
}
.ph-text-input {
  font-weight: 500;
  border-radius: 8px;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border: 1px solid #575350;
  height: 53px;
  width: 100%;
  outline: 0;
  font-size: 18px;
  padding: 2px 24px 0 24px;
}

.error-border-ph {
  border: 1px solid #ff4444;
}

.ph-text-input::placeholder {
  color: #000000;
}

.dis-btn {
  color: #717171 !important;
  background: transparent !important;
  border: 2px solid #acacac !important;
}
.delbtn {
  background: #c31818 !important;
  border-color: #c31818 !important;
  margin-right: 30px;
}

.ql-toolbar {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: white;
}

.ql-container {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: white;
}

.ql-editor {
  height: calc(100vh - 355px - 5vh) !important;
}

.disabled > .ql-toolbar > * {
  cursor: not-allowed;
}

.disabled > .ql-container > * {
  cursor: not-allowed;
}

.no-toolbar > .ql-container {
  border-radius: 10px;
}

.no-toolbar * .ql-editor {
  height: 150px !important;
}

.rightside {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 5px;
}

.pointer {
  cursor: pointer;
}
.rightsideinner {
  padding: 20px;
}
.rightsideinner h4 {
  font-family: "Basis-Grotesque-Pro-Medium" !important;
  font-weight: normal !important;
}
.paginator {
  position: absolute;
  bottom: 10px;
  /* align-self: center; */
  left: auto;
  text-align: center;
  right: auto;
  width: 74%;
}

@media screen and (max-width: 992px) {
  .paginator {
    left: calc(17vw - 100px);
  }
}

.listActive {
  font-size: 16px !important;
  background: #cfe7ec;
  color: #2f481e !important;
}

.low-priority {
  color: rgb(150, 36, 36);
  background: transparent;
  border: none;
}

.app-date .dateclass {
  width: 100%;
}
.placeholder-datenTime {
  color: #84857a !important;
  font-size: 16px !important;
  font-style: italic !important;
  font-weight: bold !important;
}

.app-date .dateclass.placeholderclass::before {
  width: 100%;
  /* content: attr(placeholder); */
  content: "";
}

@supports (-moz-appearance: none) {
  .dateclass.placeholderclass img[src*=""] {
    display: none;
  }

  .app-date .dateclass {
    content: "";
    /* padding-left: 40px !important; */
  }

  .dateclass img[src*=""] {
    display: none;
  }
}
.app-date .dateclass.placeholderclass:hover::before {
  /* width: 0%; */
  content: "";
}
.app-date .dateclass:hover {
  content: "";
  /* padding-left: 18px !important; */
}
.app-date input[type="date"]::-webkit-calendar-picker-indicator {
  /* position: absolute;
  left: 0;
  outline: none; */

  background: transparent;
  bottom: 0;
  outline: none;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.app-date input::-webkit-datetime-edit {
  position: relative;
  outline: none;
}

.app-date input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 15px;
  outline: none;
}
.app-date input[type="time"]::-webkit-calendar-picker-indicator {
  /* position: absolute;
  left: 0;
  outline: none;
  margin-left: 25px; */

  background: transparent;
  bottom: 0;
  outline: none;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f3f3f3;
  opacity: 1;
  cursor: not-allowed;
  color: rgb(84, 84, 84) !important;
}

.chips-div {
  width: 100%;
  padding: 10px 10px 10px 0px;
  min-height: fit-content;
  max-height: 125px;
}

.dialogheader {
  padding: 5px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.iconstyle {
  height: 20px;
}
.crossicon {
  cursor: pointer;
}

.disable-icon{
  cursor: not-allowed !important;
  opacity: 0.6 !important;
}

.modal-title {
  width: 100%;
}

/* anything in this div will be in center */
.center-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.gray-bordered-container {
  border: 1px solid #cccccc;
  border-radius: 8px;
}

.tab-child {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 140px);
}

.td-middle * td {
  vertical-align: middle;
}

.td-middle > tbody > tr td:last-child {
  width: 75px;
}

.sub-list {
  list-style-type: none;
  padding: 0px 10px;
  max-height: 250px;
  word-break: break-word;
}

ul.sub-list > li:first-child {
  margin-top: 0px !important;
}

ul.sub-list > li:last-child {
  margin-bottom: 0px !important;
}

.liststyle {
  margin: 18px 0px;
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #84857a;
}

.text-align-end {
  text-align: end;
}

.mainbody {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  min-height: calc(100vh - 90px);
  margin-top: 90px;
  padding-left: 230px;
}

.white-bg-table table {
  background: #fff;
  padding: 5px 20px;
  vertical-align: sub;
  border-top: none;
  border: 1px solid #fff;
  border-radius: 8px;
}

.react-tel-input :disabled {
  color: rgb(84, 84, 84) !important;
}
input[disabled] {
  cursor: not-allowed !important;
  color: rgb(84, 84, 84) !important;
}
div[disabled] {
  pointer-events: auto;
  cursor: not-allowed !important;
}

textarea[disabled] {
  pointer-events: auto;
  cursor: not-allowed !important;
}
.white-bg-table table td,
.white-bg-table table thead th {
  padding: 2px 40px;

  border: none;
}

.white-bg-table td strong {
  font-size: 14px;
  font-weight: 600;
  border-inline-end: none !important;
}

.white-bg-table td p {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #212529 !important;
  text-decoration: none !important;
}

.white-bg-table .white-bg-table-price {
  vertical-align: middle !important;
  padding: 10px 40px !important;
}
tr {
  word-break: break-word !important;
}
tbody tr {
  height: 65px;
}
.ps__rail-x,
.ps__rail-y {
  opacity: 0.6 !important;
}
.ps__rail-x {
  display: none !important;
}
.ps__thumb-y {
  background-color: #aaa !important;
}
.ps__thumb-x {
  display: none !important;
}
@media screen and (max-width: 992px) {
  .mainbody {
    padding-left: 0;
  }
}

.app-shadow {
  box-shadow: 0 0 15px 1px rgba(132, 133, 122, 0.5);
}

html {
  overflow-y: hidden !important;
}

.round-top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed !important;
}

.cursor-disabled * {
  cursor: not-allowed !important;
}

.cursor-wait * {
  cursor: wait !important;
}

.gray-bold-text {
  font-size: 16px;
  font-weight: bold;
  color: #84857a;
}

.green-bold-text {
  font-size: 16px;
  font-weight: bold;
  color: #2f481e;
}

.btn:disabled {
  cursor: not-allowed;
}

.text-email,
.text-phone {
  color: #4aa2b4;
  font-size: 16px;
  font-weight: bold;
}

.f-18px {
  font-size: 18px !important;
}
.f-14px {
  font-size: 14px !important;
}
.f-22px {
  font-size: 22px !important;
}
.f-24px {
  font-size: 24px !important;
}
/* .table-row-bottom-border > tr:not(:last-child) > td {
  border: 0;
  border-bottom: 1px solid #dee2df;
  border-radius: 0px;
} */
table tbody tr {
  box-shadow: -1px 0px 11px #00000033;
}
tr td:not(:first-child:last-child) {
  border: 1px solid #333333;
  border-right: 0px;
  border-left: 0px;
}
tr td:first-child {
  border-left: 1px solid #333333 !important;
}
tr td:last-child {
  border-top: 1px solid #333333 !important;
  border-bottom: 1px solid #333333 !important;

  border-right: 1px solid #333333 !important;
}

.total-align {
  justify-content: flex-end;
  display: flex;
  padding-right: 23px;
}

.__markerjs2_ {
  z-index: 30000 !important;
  top: 50% !important; /* Position Y halfway in */
  left: 50% !important; /* Position X halfway in */
  transform: translate(-50%, -50%) !important;
}
.__markerjs2_ img {
  object-fit: contain !important;
}
/* .__markerjs2_ a {
  display: none !important;
} */
.modal-70w {
  width: 50%;
  max-width: none !important;
}

.test-number {
  z-index: 100;
  padding: 14.5px 9px 15px 24px;
  position: absolute;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  font-size: 18px;
}

img:-moz-loading {
  visibility: hidden;
}

.customCSS {
  padding: 2px 24px 0 79px !important;
}
.customCSS_after_ten {
  padding: 2px 24px 0 88px !important;
}
.notes-spinner {
  position: relative;
  color: #64bc51 !important;
  width: 20px;
  height: 20px;
  left: -16px;
}

.un_approve_btn{
  font-family: "Basis-Grotesque-Pro-Medium";
  font-weight: 400;
  height: 40px !important;
  font-size: 12px !important;
  background: transparent !important;
  border:1px solid gray  !important;
  min-width: 154px;
}

.approve_btn{
  font-family: "Basis-Grotesque-Pro-Medium";
  font-weight: 400;
  height: 40px !important;
  font-size: 12px !important;
  background: #71db551c !important;
  border:1px solid #71db55  !important;
  min-width: 154px;
}
.check_image{
  width: 20px;
}