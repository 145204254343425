.container{
    padding: 0px 10px !important;
}

.test_heading_v4 {
    font-family: "Basis-Grotesque-Pro-Medium" !important;
    font-weight: normal !important;
}

.roomDropdown,.testDropdown{
    margin-top: 12px;
}

.sample_name_input_field {
    margin-top: 12px;
    font-size: 17px !important;
    font-weight: 500;
    border-radius: 10px;
    background: #f3f3f3 0 0 no-repeat padding-box;
    border: 1px solid #575350;
    height: 120px !important;
    width: 100%;
    outline: 0;
    font-size: 18px;
    padding: 16px;
  }

.room_det_btn_container{
    display: flex;
    justify-content: end;
}
.room_det_btn {
    background: #FFD502 !important;
    border-color: #FFD502 !important
}
.divider{
    width: 100%;
    height: 2px;
    background-color: #d9d4d4;
    margin-bottom: 20px;
}

.margin_from_bottom{
    margin-bottom: 60px;
}