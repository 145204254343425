.container{
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.test_left_side_container{
    background: #f4eadf;
    width: 60%;
    padding: 15px 5px 0px 20px;
    height: calc(100vh - 140px);
    overflow-y: auto;
}

.test_left_side_container::-webkit-scrollbar {
    /* background: transparent ;  */
    /* width: 0px ; */
}

.test_right_side_container{
    height: calc(100vh - 140px);
    overflow-y: hidden;
    width: 100%;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border-top-left-radius: 12px;
}
.default_image{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 140px);
}

