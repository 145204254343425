.container{
    margin-top: 20px;
}

.loader{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.divider{
    width: 100%;
    height: 2px;
    background-color: #d9d4d4;
    margin-top: 20px;
    margin-bottom: 10px;
}

.margin_from_bottom{
    margin-bottom: 60px;
}