.half-background {
  height: inherit;
}
.text-half {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.half-background .first-half {
  background: #000;
  width: 42%;
  flex-direction: column;
  height: auto;
}
.half-background .second-half {
  background: #f4eadf;
  width: 58%;
  height: min-content;
  min-height: 100%;
}

.half-background .page-description {
  color: #ffffff;
  margin-top: 12px;
  margin-bottom: 0px !important;
  font-weight: 500;
  font-size: 22px;
  padding: 0px 15%;
  /* width: 302px; */
  text-align: center;
}
.half-background .page-subprice {
  letter-spacing: 1.47px;
  color: #ffffff;
  text-align: center;
  font-weight: 700;
  font: normal normal bold 44px/82px Basis-Grotesque-Pro-Regular;
  margin-left: -20px;
}
.half-background .letter-spacing {
  letter-spacing: 1.47px;
  /* width: auto !important; */
  font-size: 23px !important;
}
.qr-code {
  padding: 10px 40px;
}
.qr-code .qr-img {
  width: 280px;
}
.qr-code .qr-text {
  position: absolute;
  bottom: 150px;
  font-size: 23px;
  padding-left: 62px;
  font-weight: bolder;
}
.qr-code .qr-scan-code {
  padding: 11px 37px 25px 20px;
  position: absolute;
  width: 300px;
}
.pay-div {
  background-color: #ffff;
  padding: 50px 70px;
  border-radius: 8px;
}

/* @media screen and (max-width: 1300px) {
  .qr-img {
    width: 260px;
  }
}
@media screen and (max-width: 1200px) {
  .qr-img {
    width: 250px;
  }
} */
