.maintenance-cap-text {
  font-weight: bolder;
  font-size: 35px;
  margin-bottom: 0px;
  color: black;
  margin-top: 22px;
}

.maintenance-small-text {
  font-size: 26px;
  color: black;
}
