.head-tab-btns {
  background: #fff;
  padding: 0 30px;
}
.head-tab-btns .btn {
  color: #84857a;
  font-size: 15px;
  font-weight: 500;
  border-radius: 2px;
  padding: 10px 15px;
  border: 0;
  border-bottom: 3px solid transparent;
  min-width: 192px;
}
.head-tab-btns .btn.active {
  background: #e8f9d2;
  font-weight: 700;
  border-color: #74b44a;
  color: #2f481e;
}

.inspections-display th {
  font-size: 12px !important;
  font-style: italic;
}
.inspections-display td strong {
  font-size: 14px;
  border-inline-end: none !important;
}
.inspections-display td {
  vertical-align: middle !important;
  padding: 5px 15px 5px 18px;
}
.btn-danger {
  border-radius: 8px !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  text-align: right;
}

.auth-input-field-inspection::placeholder {
  font-style: italic;
  font-weight: bold;
}
p.labNameCOC {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.placeholder-active {
}
.success-coc {
  background-color: rgb(212, 237, 218);
  border-color: rgb(195, 230, 203);
  width: 100%;
  text-align: center;
  padding: 14px;
}
.icon-picker {
  position: absolute;
  right: 25px;
  top: 11px;
}

.checkbox-center {
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
}

.row-sub-text {
  font-size: 14px;
}

.inspections-display td p,
.inspections-display td a {
  display: block;
  width: 183px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px !important;
  text-overflow: ellipsis;
  color: #4aa2b4;
  text-decoration: none !important;
}
.trunk-text {
  display: block;
  max-width: 155px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.m-auto-custom {
  margin: auto 0px;
}
.trunk-name {
  max-width: 105px;
}
.trunk-cname {
  max-width: 115px;
}

.trunk-customer-name {
  max-width: 174px;
  width: 180px;
}
.inspections-display span {
  font-size: 14px;
}

.pd-top-notes {
  padding-top: 35px;
}
.inspectors-onSite strong {
  position: absolute;
  margin-top: 6px;
}

.text-progress {
  width: 100px !important;
  color: #84857a !important;
  font-weight: bold !important;
  letter-spacing: 0px;
  margin-top: 2px !important;
  text-align: center !important;
}

.propertyInfoBlock {
  padding: 25px 20px;
  width: 100%;
  height: 120px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 4px;
  text-align: initial;
  box-shadow: 0 2px 52px -20px rgba(132, 133, 122, 0.5);
}

.address-wrapper {
  display: block;
  max-width: 155px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.propertyInfoBlock .propertyInfoName {
  color: #2f481e;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 4px;

  line-height: 22px;
}

.propertyInfoBlock .propertyInfoDetails {
  color: #4aa2b4;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
  letter-spacing: 0;
  line-height: 19px;
}
.propertyInfoBlock .next-btn {
  font-size: 24px;
  border: 0;
  background: 0;
  color: #777;
  padding: 8px 12px;
}

.status-text p {
  /* margin: 15px 0px 15px 30px; */
  color: #84857a;
  font-size: 14px;
  margin-top: 15px;
  margin-top: 14px !important;
  font-weight: bold;
  margin: auto;
}

.inspection-plus-btn svg {
  color: rgb(132, 133, 122) !important;
  height: 22px;
  width: 22px !important;
  border-radius: 1px;
  margin-left: 2px !important;
}

.top-inspector {
  width: 100%;
  margin-right: 18px;
  height: 47px;
  padding: 7px 15px;
  background: white;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 52px -20px rgba(132, 133, 122, 0.5);
}

.left-div-inspector {
  margin-top: 20px;
  width: 100%;
  display: flex;
}
.other-inspector {
  width: 91%;
  margin-right: 15px;
  height: 46px;
  padding: 7px 15px;
  background: white;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 52px -20px rgba(132, 133, 122, 0.5);
}
.inspector-span-line {
  width: 38px;
  border-bottom: 1px solid #84857a;
  border-left: 1px solid #84857a;
  margin-left: 38px;
}
.top-inspector img {
  width: 32px;
  height: 32px;
}
.other-inspector img {
  width: 32px;
  height: 32px;
}
.removeDiv {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.firstDiv {
  height: 42px;
}
.view-more-tag {
  text-align: center;
  font-weight: 700;
  color: #4aa2b4;
  cursor: pointer;
}
.inspection-table table td,
.inspection-table table thead th {
  padding: 2px 5px;
  border: none;
}
.inspection-td-btn {
  display: flex;
  justify-content: space-between;
  width: 100px;
  margin-right: 20px;
}

.inspection-table table {
  background: #fff;
  padding: 5px 20px;
  vertical-align: sub;
  border-top: none;
  border: 1px solid #fff;
  border-radius: 8px;
}

.inspection-table table td,
.inspection-table table thead th {
  padding: 2px 10px;
  vertical-align: middle;
  border: none;
}

.inspection-table td strong {
  font-size: 14px;
  font-weight: 600;
  border-inline-end: none !important;
}

.inspection-table td p {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #212529 !important;
  text-decoration: none !important;
}
.inspection-checkbox {
  width: 40px;
  height: 20px;
}
.arrow-down {
  height: 24px;
  position: absolute;
  width: 24px;
  z-index: 1;
  transform: rotate(45deg);
  background-color: #4aa2b4;
  margin: 1px 0px 0px -4px;
}
.documents-rectangle {
  height: 56px;
  border-radius: 11px 11px 1px 1px;
  background-color: #4aa2b4;
}
.table-docs-radius {
  border-radius: 0px 0px 8px 8px;
}

.num-documents-selected {
  font-size: 18px;
  color: white;
}
.table .document-download {
  height: 40px;
  border-radius: 11px;
  min-width: 40px;
  background: white;
  border: white;
}

.inspection-area-address {
  color: #84857a;
  font-size: 13px;
  font-style: italic;
  font-weight: bold;
}

.view-all-inspectors {
  display: flex;
  background: transparent;
  border: transparent;
  color: rgb(74, 162, 180);
  margin: 40px 0px 5px;
  justify-content: flex-end;
  margin-top: 0px;
}
.activeTime {
  pointer-events: none !important;
  position: absolute !important;
  transform: translate(0, 26px) scale(1) !important;
  transform-origin: top left !important;
  transition: all 0.2s ease-out !important;
  margin-top: -8px !important;
}
.marginIcon {
  transition: all 0.2s ease-out;
}
.marginDateIcon {
  transition: all 0.2s ease-out;
}

@media screen and (max-width: 1085px) and (min-width: 992px) {
  .propertyInfoBlock {
    height: 140px;
  }
  .inspections-display th {
    padding: 0px 10px !important;
  }

  .inspections-display td {
    vertical-align: middle !important;
    padding: 8px 6px 3px 6px !important;
  }
}

@media screen and (max-width: 1200px) and (min-width: 600px) {
  .propertyInfoBlock {
    height: 140px;
  }
  .inspections-display td {
    vertical-align: middle !important;
    padding: 8px 3px 3px 13px;
  }
}

.spacing {
  border-spacing: 0 8px !important;
}

.observation-details-section {
  padding-bottom: 1rem;
}

.scroll-shadow {
  box-shadow: 0 0 15px 1px rgb(132 133 122 / 50%);
  margin: 5px 12px 12px;
}

.app-date .dateclass:focus:not(.focus-visible) {
  outline: 0px transparent !important;
}

.foo-modal-backdrop.show {
  opacity: 0.2 !important;
}

.foo-modal-backdrop-notes.show {
  opacity: 0.8 !important;
}

.foo-modal-backdrop-photos.show {
  opacity: 0.3 !important;
  z-index: 1050;
}

.general-info .next-btn:focus {
  outline: none;
  background-color: lightgray;
  border-radius: 5px;
}

.image-modal {
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1;
  max-height: 50vh;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
.force-small {
  font-size: 14px;
  font-weight: 600;
  color: #84857a;
}
.padding-invoice-td {
  padding: 5px 10px;
}

.modal-width > .modal-content {
  width: 50vw;
}
.fee-modal-shadow {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.5) !important;
}
.bottom-shadow {
  box-shadow: 0px 14px 15px -15px rgb(0 0 0 / 15%);
}

.add-room-text {
  justify-content: center;
  flex: 1;
  padding-left: 30px;
  padding-top: 3px;
  align-items: center;
}
.tasklist {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border: 1px solid hsl(26deg 4% 33%);
  border-radius: 12px;
}
.addItemTaskList img {
  padding-left: 10px;
  cursor: pointer;
}
.tasklist ul {
}
.tasklist li {
  border-bottom: 1px solid hsl(26deg 4% 33%);
  padding: 10px 16px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 18px;
}

.tasklist li:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom: none;
  padding: 10px 16px;
}

.image-text {
  /* text-decoration: underline; */
  font: normal normal normal 14px/23px Basis-Grotesque-Pro-Regular;
  letter-spacing: 0px;
  color: #000000;
  margin: 5px 0px 9px 4px;
}
.image-approved-reject-notes {
  max-width: 370px;
  min-width: 300px;
  max-height: 400px;

  margin-left: 0px;
  object-fit: contain;
  /* margin-top: 28px; */
  margin-bottom: 20px;
}
.image-approved-reject {
  max-width: 370px;
  /* min-width: 300px; */
  max-height: 400px;

  margin-left: 0px;
  object-fit: contain;
  /* margin-top: 28px; */

  margin-bottom: 20px;
}
@media screen and (min-width: 1400px) {
  .image-approved-reject {
    /* margin-top: 51px; */
  }
}

.image-observation-modal-text {
  font-family: "Basis-Grotesque-Pro-Medium";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
  margin-bottom: 15px;
}

.d-center-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.before-spinnerImg {
  color: #14444c;
  z-index: 999;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.box-general-info {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #64bc514f;
  border: 2px solid #64bc51;
  border-radius: 8px;
  height: 115px;
  opacity: 1;
}
.info-address {
  display: flex;
  justify-content: space-between;
  padding-inline: 22px;
}
.info-address .info-address-details {
  color: #008ed8;
  font-size: 16px;
}
.info-address .compressed-property {
  display: -webkit-box;
  max-width: 235px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.property-span {
  display: -webkit-box;
  max-width: 494px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.maxWidthName {
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
.info-address .name {
  font-family: "Basis-Grotesque-Pro-Medium" !important;
  font-size: 19px;
  font-weight: normal;
  font-style: normal;
}
.refreshing-observation {
  animation: spinner-border 0.75s linear infinite;
}
.bg-prop-img {
  background-image: url("../../../assets/icons/map-img@2x.png");
}
.modalToggle {
  display: flex;
  justify-content: space-between;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f2f2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: lightgray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:checked {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  /* background-color: #82e753; */
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  background-color: #82e753;
  /* 82E753 */
}

input:checked + .slider {
  box-shadow: 0 0 3px #82e753;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switches {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
  margin-top: 15px;
}

.switches input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliders {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f2f2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliders:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: lightgray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: #000000;
}

.sliders:checked {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sliders:before {
  background-color: #82e753;
  /* 82E753 */
}

input:checked + .sliders {
  box-shadow: 0 0 3px #82e753;
}
input + .sliders {
  box-shadow: 0 0 3px black;
}

input:checked + .sliders:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliderss */
.sliders.rounds {
  border-radius: 34px;
}

.sliders.rounds:before {
  border-radius: 50%;
}

.deleteCheckbox[type="checkbox"] {
  height: 15px;
  width: 15px;
  margin-right: 15px;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.delete-btn {
  height: 30px;
  margin-right: 8px;
  margin-top: 5px;
}
.no-wrap {
  white-space: "nowrap";
}

.room-heading {
  color: black;
  font-size: 19px;
  margin-left: 2px;
}

.room-sub-heading {
  color: black;
  font-size: 18px;
  margin-left: 2px;
}

.modal-ok-btn {
  padding: 20px 80px !important;
}

.bg-color {
  background: #f4eadf;
}
