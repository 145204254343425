.listing_detail{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding: 0 4px;

}
.listing_heading{
    font-weight: bold;
    font-size: large;
}

